<template>
  <b-table-simple>
    <tbody v-for="(key, index) in Object.keys(groupedProducts)" :key="index">
      <tr>
        <td class="pr-4 product-name">
          <span v-if="groupedProducts[key]?.quantity < 9">0</span
          >{{ groupedProducts[key]?.quantity }}
          <span class="ml-4">
            <strong v-if="groupedProducts[key]?.code">
              {{ groupedProducts[key]?.code }} -
            </strong>
            <span>{{ groupedProducts[key]?.name }}</span>
          </span>
        </td>
        <td>
          <div class="d-flex justify-content-end">
            <Trash
              class="icon trash"
              @click="
                groupedProducts[key]?.products
                  ? removeByKitTemplateId(groupedProducts[key]?.id)
                  : removeSurgeryProductTemplate(groupedProducts[key]?.id)
              "
              v-b-tooltip.hover
              :title="
                groupedProducts[key]?.products
                  ? 'Remover kit vinculado'
                  : 'Remover produto vinculado'
              "
            />
          </div>
        </td>
      </tr>
      <tr
        v-for="(product, index) in groupedProducts[key]?.products"
        :key="index"
        class="subrow"
      >
        <td>
          <b-row class="w-100">
            <b-col cols="1" class="d-flex justify-content-center">
              <ArrowUp class="icon arrow pt-2" />
            </b-col>

            <b-col cols="1">
              <div class="td-content">
                <span v-if="product?.quantity < 9">0</span
                >{{ product?.quantity }}
              </div>
            </b-col>
            <b-col cols="10">
              <div class="td-content w-100">
                <strong v-if="product?.product?.barcode">
                  {{ product?.product?.barcode }} -
                </strong>

                <span>{{ product?.product?.name }}</span>
              </div>
            </b-col>
          </b-row>
        </td>
        <td>
          <div class="d-flex justify-content-end">
            <Trash
              class="icon trash"
              @click="removeSurgeryProductTemplate(product?.id)"
              v-b-tooltip.hover
              title="Remover produto vinculado"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
export default {
  components: {
    Trash: () => import('@/assets/icons/trash.svg'),
    ArrowUp: () => import('@/assets/icons/arrow-up2.svg')
  },
  props: {
    surgeryProductTemplates: { type: Array, default: () => [] }
  },
  computed: {
    groupedProducts() {
      const groupedTemplates = []
      this.surgeryProductTemplates.map(template => {
        if (!template?.kit_template_id) {
          const product = {
            ...template,
            code: template.product?.barcode,
            name: template.product?.name,
            quantity: template.quantity
          }
          this.$set(groupedTemplates, template?.id, product)
          return
        }

        if (!groupedTemplates[template?.kit_template_id]) {
          const templateGroup = {
            ...template,
            id: template?.kit_template_id,
            kitTemplate: template?.kit_template,
            code: template?.kit_template?.code,
            name: template?.kit_template?.name,
            products: []
          }
          this.$set(groupedTemplates, template?.kit_template_id, templateGroup)
        }

        groupedTemplates[template.kit_template_id].products.push(template)
      })
      return groupedTemplates
    }
  },
  methods: {
    removeSurgeryProductTemplate(surgeryProductTemplateId) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir este produto? Está ação não poderá ser desfeita</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit('removeSurgeryProductTemplate', surgeryProductTemplateId)
        }
      })
    },
    removeByKitTemplateId(kitTemplateId) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir este kit? Está ação não poderá ser desfeita</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit('removeByKitTemplateId', kitTemplateId)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  display: block;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.trash {
  stroke: var(--states-red-soft);
}

.arrow {
  transform: scale(1.5);
}

.subrow {
  background: var(--neutral-100, #c6ceeb);
}

.product-name {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #525c7a;
}
</style>
